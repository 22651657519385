import { axiosInstance, namespaces } from 'services/api'

import { removeCookieValue, setCookieValue } from 'helpers/cookies'

import { IAuth } from '../types'

type TPayload = {
  email: string
  password: string
}

const signIn = async (payload: TPayload): Promise<IAuth> => {
  try {
    const { email, password } = payload
    removeCookieValue(namespaces.TOKEN_NAME)
    axiosInstance.defaults.headers.common['Authorization'] = ''

    axiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = ``

      return config
    })

    const response = await axiosInstance.post(
      namespaces.baseURLLogin,
      {
        email,
        password,
        returnSecureToken: true
      },
      {
        params: {
          key: namespaces.API_KEY
        }
      }
    )

    const { data } = response

    setCookieValue(namespaces.TOKEN_NAME, data.idToken || '')

    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${data.idToken}`

    axiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${data.idToken}`

      return config
    })

    const [
      { data: userInfo },
      { data: permission },
      { data: dynamics_graphics }
    ] = await Promise.all([
      await axiosInstance.get(namespaces.baseURLNamespaceUsers, {
        headers: {
          Authorization: `Bearer ${data.idToken}`
        }
      }),

      await axiosInstance.get(namespaces.baseURLNameSpaceFiltersPermissions, {
        headers: {
          Authorization: `Bearer ${data.idToken}`
        }
      }),

      await axiosInstance.get(
        namespaces.baseURLNameSpaceFiltersPermissionsDynamicGraphics,
        {
          headers: {
            Authorization: `Bearer ${data.idToken}`
          }
        }
      )
    ])

    const { data: usersColors } = await axiosInstance.get(
      namespaces.baseURLNameSpaceFiltersClientsColors,
      {
        headers: {
          Authorization: `Bearer ${data.idToken}`
        },
        params: {
          client: userInfo.client
        }
      }
    )

    const lastRefresh = new Date()

    let findUserPermission: any = undefined

    findUserPermission = permission[userInfo?.role || '']

    if (!findUserPermission) {
      if (permission) {
        Object.keys(permission).forEach(function (chave) {
          if (
            findUserPermission === undefined &&
            permission[chave]?.default === true
          ) {
            findUserPermission = permission[chave]
          }
        })
      }
    }

    const responseData: IAuth = {
      accessToken: data.idToken,
      email: userInfo.email,
      name: userInfo.name,
      role: userInfo.role || '',
      client: userInfo.client || '',
      project: userInfo.project || '',
      refreshToken: data.refreshToken,
      isLogged: true,
      lastRefresh,
      usersColors: JSON.stringify(usersColors || ''),
      dynamics_graphics: JSON.stringify(dynamics_graphics || ''),
      findUserPermission
    }

    setCookieValue(namespaces.TOKEN_NAME, responseData.accessToken || '')
    setCookieValue('role', responseData.role || '')
    setCookieValue('client', responseData.client || '')
    setCookieValue(
      namespaces.REFRESH_TOKEN_NAME,
      responseData.refreshToken || ''
    )
    setCookieValue('project', userInfo.project || '')
    setCookieValue(namespaces.LAST_REFRESH, lastRefresh)
    setCookieValue('email', responseData?.email || '')

    localStorage.setItem(
      'findUserPermission',
      JSON.stringify(findUserPermission || '')
    )
    localStorage.setItem('usersColors', JSON.stringify(usersColors || '') || '')
    localStorage.setItem('timer', String('3600'))
    localStorage.setItem(
      'dynamics_graphics',
      JSON.stringify(dynamics_graphics || '') || ''
    )

    return responseData
  } catch (err) {
    throw err
  }
}

export default signIn
