import { createStore } from 'effector'
import { getCookieValue, removeCookieValue } from 'helpers/cookies'
import { namespaces } from 'services/api'

import { IAuth } from './types'

import * as actions from './actions'

interface IUseAuth {
  data?: IAuth
  isLoading?: boolean
  hasError?: boolean
}

export const store = createStore<IUseAuth>(
  {
    isLoading: false,
    hasError: false,
    data: {
      isLogged: !!getCookieValue(namespaces.TOKEN_NAME),
      name: '',
      email: getCookieValue('email'),
      role: getCookieValue('role'),
      client: getCookieValue('client'),
      accessToken: getCookieValue(namespaces.TOKEN_NAME),
      refreshToken: getCookieValue(namespaces.REFRESH_TOKEN_NAME),
      usersColors: localStorage.getItem('usersColors'),
      dynamics_graphics: localStorage.getItem('dynamics_graphics'),
      project: getCookieValue('project') || '',
      findUserPermission: localStorage.getItem('findUserPermission')
        ? JSON.parse(localStorage.getItem('findUserPermission') || '')
        : {}
    }
  },
  {
    name: 'auth'
  }
)

store.on(actions.handleSignIn, (state) => ({
  ...state,
  isLoading: true,
  hasError: false
}))

store.on(actions.handleSignIn.doneData, (state, data) => ({
  ...state,
  data,
  isLoading: false,
  hasError: false
}))

store.on(actions.handleSignIn.fail, (state) => ({
  ...state,
  isLoading: false,
  hasError: true
}))

store.on(actions.handleRefreshToken, (state) => ({
  ...state,
  isLoading: true,
  hasError: false
}))

store.on(actions.handleRefreshToken.doneData, (state, data) => ({
  ...state,
  data: {
    ...state.data,
    ...data
  },
  isLoading: false,
  hasError: false
}))

store.on(actions.handleRefreshToken.fail, (state) => ({
  ...state,
  isLoading: false,
  hasError: true
}))

store.on(actions.handleLogout, (state) => {
  removeCookieValue(namespaces.TOKEN_NAME)
  removeCookieValue(namespaces.LAST_REFRESH)
  removeCookieValue(namespaces.REFRESH_TOKEN_NAME)
  removeCookieValue('email')
  removeCookieValue('role')
  removeCookieValue('menu')
  removeCookieValue('client')
  removeCookieValue('project')
  localStorage.removeItem('usersColors')
  localStorage.removeItem('findUserPermission')
  localStorage.removeItem('timer')
  localStorage.removeItem('key')
  localStorage.removeItem('dynamics_graphics')

  return {
    ...state,
    data: {
      isLogged: false
    }
  }
})
