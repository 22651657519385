import Cookies from 'js-cookie'

export const getCookieValue = (key: any) => {
  return Cookies.get(key)
}

export const setCookieValue = (key: any, value: any) => {
  const domain = ''
  Cookies.set(key, value, { domain })
}

export const removeCookieValue = (key: any) => {
  const domain = ''

  Cookies.remove(key, { domain })
}
