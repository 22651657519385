import { useEffect } from 'react'

import { BrowserRouter, useLocation } from 'react-router-dom'

import Routes from './routes'
import AppProviders from './AppProviders'
import { getCookieValue } from 'helpers/cookies'
import moment from 'moment'
import { handleOpenModal } from 'modules/timer/actions'
import { LAST_REFRESH, TIME_TO_REFRESH_TOKEN } from 'services/apiConfig'

import { handleSignIn } from 'modules/auth/actions'

setInterval(async () => {
  const now = new Date()
  const lastRefresh = getCookieValue(LAST_REFRESH)
  const email = getCookieValue('email') || ''
  const token = localStorage.getItem('key') || ''
  const isActive = localStorage.getItem('isActive') || ''

  if (moment(now).diff(lastRefresh, 'minutes') >= TIME_TO_REFRESH_TOKEN) {
    if (isActive === 'true') {
      await handleSignIn({ email, password: token })
    } else {
      handleOpenModal()
    }
  }
}, 60000)

function ScrollToTopOnMount() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return null
}

function App() {
  return (
    <AppProviders>
      <BrowserRouter>
        <ScrollToTopOnMount />
        <Routes />
      </BrowserRouter>
    </AppProviders>
  )
}

export default App
